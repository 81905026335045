import request from '../utils/request'

export function transStatus(data) {
    return request({
        url: 'get-trans-status',
        method: 'get',
        direct: 1,
        params: data
    });
}
export function collectionDetail(data) {
    return request({
        url: 'collection/detail',
        method: 'post',
        direct: 1,
        data
    });
}
export function nftDetail(data) {
    return request({
        url: 'collection/token',
        method: 'post',
        direct: 1,
        data
    });
}
export function collectionOffer(data) {
    return request({
        url: 'collection/offer',
        method: 'post',
        direct: 1,
        data
    });
}
export function userIssued(data) {
    return request({
        url: 'user/issued',
        method: 'post',
        direct: 1,
        data
    });
}
export function userReceived(data) {
    return request({
        url: 'user/received',
        method: 'post',
        direct: 1,
        data
    });
}
export function userAccount(data) {
    return request({
        url: 'user/account',
        method: 'post',
        direct: 1,
        data
    });
}
export function search(data) {
    return request({
        url: 'search',
        method: 'post',
        direct: 1,
        data
    });
}
export function activity(data) {
    return request({
        url: 'collection/activity',
        method: 'post',
        direct: 1,
        data
    });
}
export function userUpload(data) {
    return request({
        url: 'user/upload',
        method: 'post',
        direct: 1,
        data
    });
}
export function userSign(data) {
    return request({
        url: 'user/sign',
        method: 'post',
        direct: 1,
        data
    });
}
export function userInfo(data) {
    return request({
        url: 'user/info',
        method: 'post',
        direct: 1,
        data
    });
}
export function userIgnore(data) {
    return request({
        url: 'user/ignore',
        method: 'post',
        direct: 1,
        data
    });
}
export function updateMeta(data) {
    return request({
        url: 'collection/update-meta',
        method: 'post',
        direct: 1,
        data
    });
}
export function userOffer(data) {
    return request({
        url: 'user/offer',
        method: 'post',
        direct: 1,
        data
    });
}
