import Vue from 'vue'
import Vuex from 'vuex'
import { message } from "ant-design-vue";
import { Network } from "@/config/constants";
import nftSdk from "@/sdk";
import { userInfo } from "@/api";
import { setStorage,getStorage } from '@/utils';
const provider = nftSdk.getProvider();
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    device: 'desktop',
    account: null,
    userBalance: 0,
    chainId: null,
    desireChainId: null,
    isMobile: false,
    signModalVisible:false,
    loginOut:getStorage('loginOut')
  },
  getters: {
    chainName(state) {
      let chain = Network[state.chainId];
      if (chain) {
        return chain['chainName'];
      } else {
        return '';
      }
    },
    chainType(state) {
      let chain = Network[state.chainId];
      if (chain) {
        return chain['chainType'];
      } else {
        return '';
      }
    },
    isConnected(state) {
      if (!state.chainId) {
        return false;
      }
      return state.chainId == state.desireChainId;
    }
  },
  mutations: {
    SET_ACCOUNT: (state, account) => {
      // account =  "0xb7A9DEd5D6621a596A53D88531a3e1c23ceEA555";
      state.account = account
    },
    SET_CHAINID: (state, chainId) => {
      state.chainId = chainId;
    },
    SET_DESIRE_CHAINID: (state, desireChainId) => {
      state.desireChainId = desireChainId;
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_userBalance: (state, userBalance) => {
      state.userBalance = userBalance;
    },
    SET_ISMOBILE: (state, value) => {
      state.isMobile = value;
    },
    SET_signModalVisible: (state, value) => {
      state.signModalVisible = value;
    },
    SET_LOGINOUT: (state, value) => {
      state.loginOut = value
      setStorage('loginOut',value)
    },
  },
  actions: {
    updateMobile({ commit }, value) {
      commit('SET_ISMOBILE', value)
    },
    updateSignModalVisible({ commit }, value) {
      commit('SET_signModalVisible', value)
    },
    async switchNetwork({ state, commit }, chainId) {
      if (chainId) {
        commit('SET_DESIRE_CHAINID', chainId);
      }
      if (!chainId) {
        chainId = state.desireChainId;
      }
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId }],
        });
        commit('SET_CHAINID', chainId);
        return true;
      } catch (switchError) {
        console.log("switchError", switchError.message);
        if (switchError.code == "4001") return;
        // This error code indicates that the chain has not been added to MetaMask.
        try {
          let fields = ["chainId", "chainName", "nativeCurrency", 'rpcUrls', 'blockExplorerUrls', 'iconUrls'];
          let obj = {};
          for (let i = 0; i < fields.length; i++) {
            obj[fields[i]] = Network[chainId][fields[i]];
          }
          let params = [
            obj,
          ];
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params,
          });
          if (!state.account) {
            let accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
            let account = accounts[0];
            commit('SET_ACCOUNT', account);
          }
          return true;
        } catch (addError) {
          console.log("addError", addError.message);
        }
      }
      return false;
    },
    async connected() {
      if (!window.ethereum) {
        message.error("install MetaMask first!");
        return;
      }
      try {
        let accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        let account = accounts[0];
        this.dispatch('updateAccount', account);
        return true;
      } catch (err) {
        console.log('connect error', err.message);
      }
      return false;
    },
    async updateAccount({ commit }, account) {
      setStorage("signAccount", account)
      provider.off("block");
      if(account){
        userInfo({'address':account}).then(async({data})=>{
          if(!data.verified){
            this.dispatch('updateSignModalVisible',true);
          }else{
            commit('SET_ACCOUNT', account);
            commit('SET_LOGINOUT', false);
            provider.on("block", async() => {
              const balance = await nftSdk.getBalance(account);
              commit('SET_userBalance', balance)
            })
            const balance = await nftSdk.getBalance(account);
            commit('SET_userBalance', balance)
          }
        })
      }else{
        commit('SET_ACCOUNT', account);
        commit('SET_LOGINOUT', true);
        commit('SET_userBalance', 0)
      }
    },
  }
})
