const MARKET_CONTRACT = '0xbF17784cdCb36B4BEBDae7dAb7a5645fd828A903';
//env testing
const Network = {
    // '0x1': {
    //     chainId: '0x1',
    //     chainType: '1',
    //     chainName: 'Ethereum',
    //     chainNameMobile: 'ETH',
    //     rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    //     blockExplorerUrls: ['https://etherscan.io'],
    //     nativeCurrency: {
    //         name: 'ETH',
    //         symbol: 'ETH',
    //         decimals: 18,
    //     },
    // },
    '0x5': {
        chainId: '0x5',
        chainType: '1',
        chainName: 'Goerli',
        chainNameMobile: 'ETH',
        rpcUrls: ['https://goerli.infura.io/v3/56c5a245c16f4fe392b078a69e004a0b'],
        blockExplorerUrls: ['https://goerli.etherscan.io/'],
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
    }
};
export { Network, MARKET_CONTRACT };
