import BigNumber from 'bignumber.js';
import moment from 'moment';
BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN });

const showAddress = function (text) {
    if (text) {
        return text.substring(0, 6) + '...' + text.substring(38, 42);
    }
};

const isNullOrEmpty = function (val) {
    if (val == null || val == '' || typeof val == undefined) {
        return true;
    } else {
        return false;
    }
};

const timeFormat = (value, format, $default = null) => {
    if (!value) {
        if ($default) {
            return $default;
        }
        //return moment.unix(0).utc().format(format);
        return '';
    }
    if (/^\d+$/.test(value)) {
        return moment.unix(value).utc().format(format);
    } else {
        return moment(value).format(format);
    }
};

const decimals = function (val, size = 4, fillZero = true) {
    if (typeof val === 'undefined' || val === null) {
        val = BigNumber(0);
    } else if (!(typeof val === 'string' || val instanceof String || typeof val === 'number')) {
        val = val.toString();
    }
    val = BigNumber(val);
    if (val.isNaN()) {
        val = BigNumber(0);
    }
    if (fillZero) {
        return BigNumber(Math.round(BigNumber(val.toString()).multipliedBy(Math.pow(10, size))) / Math.pow(10, size)).toString();
    } else {
        return BigNumber(BigNumber(val.toString()).toFixed(size)).toString();
    }
};

const decimalsUnit = function (val, size = 4) {
    if (!val) {
        return val === 0 ? '0.000' : '-';
    }
    let unit = '';
    if (val >= 1e3 && val < 1e6) {
        val = val / 1e3;
        unit = 'K';
        size = 2;
    }
    if (val >= 1e6 && val < 1e9) {
        val = val / 1e6;
        unit = 'M';
        size = 2;
    }
    if (val >= 1e9) {
        val = val / 1e9;
        unit = 'B';
        size = 2;
    }
    return new BigNumber(val.toString()).toFixed(size) + unit;
};
const decimalsFormat = function (val, size = 3) {
    if (!val) {
        return val === 0 ? 0 : '-';
    }
    return new BigNumber(new BigNumber(val.toString()).toFixed(size)).toFixed();
};
const timeToString = function (time) {
    time = parseInt(time);
    if (time === 0) {
        return '';
    }
    if (time <= 60) {
        return time + ' seconds';
    }
    if (time <= 3600) {
        return parseInt(time / 60) + ' Minus';
    }
    if (time <= 86400) {
        return parseInt(time / 3600) + ' Hours';
    }
    if (time <= 2 * 86400) {
        return parseInt(time / 86400) + ' Day';
    }
    return parseInt(time / 86400) + ' Days';
};

const leftTime = function (time) {
    time = parseInt(time);
    let now = parseInt(Date.now() / 1000);
    if (time < now) {
        return '';
    }
    return timeToString(now - time);
};
export { isNullOrEmpty, timeFormat, decimals, decimalsUnit, decimalsFormat, showAddress, leftTime, timeToString };
