let basePath = '/api/'
switch (process.env.NODE_ENV) {
    //product
    case 'production':
        basePath = '/api/';
        break;
    //test
    default:
        basePath = 'https://www.meadowfair.io/api/';
        break;
}
module.exports = {
    basePath
}
