import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/market',
    redirect: '/market',
    component: () => import('@/views/Layouts/appLayout.vue'),
    children: [
      {
        path: '',
        name: 'market',
        component: () => import('@/views/App/market.vue'),
      },
      {
        path: '/market/:chain/:contract/:id/:owner?',
        name: 'market-detail',
        component: () => import('@/views/App/market-detail.vue'),
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/views/App/search.vue'),
      },
      {
        path:'/collection/:identify',
        name:'collection-detail',
        component: ()=> import('@/views/App/collection-detail.vue')
      },
      {
        path:'/account/:account',
        name:'account',
        component: ()=> import('@/views/App/account.vue')
      },
      {
        path: '/offer-price',
        name: 'offer-price',
        component: () => import('@/views/App/offer-price.vue'),
      }
    ]
  }
]
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:routes
})
