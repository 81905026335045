import BigNumber from 'bignumber.js';
export const clone = function (data) {
    if (!data || typeof data === 'string') {
        return data;
    }
    return JSON.parse(JSON.stringify(data));
};
// localStorage
export const setStorage = function (key, value) {
    try {
        if (typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number') {
            localStorage.setItem(key, value);
        } else if (value instanceof Array) {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            const data = getStorage(key) || {};
            localStorage.setItem(key, JSON.stringify(Object.assign(data, clone(value))));
        }
    } catch (err) {
        console.log(err); // eslint-disable-line
    }
};
export const getStorage = function (key) {
    try {
        let value;
        try {
            value = JSON.parse(localStorage.getItem(key));
        } catch (err) {
            value = localStorage.getItem(key);
        }
        return value;
    } catch (err) {
        console.log(err); // eslint-disable-line
    }
};

export const getOptionItem = function (val, array, value) {
    return array.filter((item) => val === item[value || 'value'])[0] || {};
};

export const decimalsPrecision = function (val, precision, decimals = 4) {
    if (!val) {
        return '0.' + '0'.repeat(decimals);
    }
    let num = BigNumber(val.toString()).div(BigNumber(10).pow(precision)).toFixed(decimals);
    return num;
};

export const decimals = function (val, size = 4, fillZero = true) {
    if (typeof val === 'undefined' || val === null) {
        val = BigNumber(0);
    } else if (!(typeof val === 'string' || val instanceof String || typeof val === 'number')) {
        val = val.toString();
    }
    val = BigNumber(val);
    if (val.isNaN()) {
        val = BigNumber(0);
    }
    if (fillZero) {
        return BigNumber(val.toString()).toFixed(size);
    } else {
        return BigNumber(BigNumber(val.toString()).toFixed(size)).toString();
    }
};
