import { ethers, constants } from 'ethers';
import { MARKET_CONTRACT } from '@/config/constants';
const NftMarket_ABI = require('./abi/NftMarket.json');
const IERC20_ABI = require('./abi/IERC20.json');
const IERC721_ABI = require('./abi/IERC721.json');
const IERC1155_ABI = require('./abi/IERC1155.json');
const nftSdk = {};
nftSdk.MaxUint256 = constants.MaxUint256;
nftSdk.AddressZero = constants.AddressZero;
nftSdk.getProvider = () => {
    let _provider;
    if (window.ethereum) {
        _provider = new ethers.providers.Web3Provider(window.ethereum);
    } else if (window.web3) {
        _provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
    }
    return _provider;
};

nftSdk.getSigner = () => {
    const provider = nftSdk.getProvider();
    const signer = provider ? provider.getSigner() : null;
    return signer;
};

nftSdk.getBalance = async (address) => {
    const provider = nftSdk.getProvider();
    const balance = await provider.getBalance(address);
    const balanceInEth = ethers.utils.formatEther(balance);
    return balanceInEth;
};

nftSdk.getTokenBalance = function (token_contract, address) {
    let erc20Contract = nftSdk.connectContract(token_contract, IERC20_ABI);
    return erc20Contract.balanceOf(address);
};

nftSdk.get1155Balance = function (token_contract, address, tokenId) {
    let erc1155Contract = nftSdk.connectContract(token_contract, IERC1155_ABI);
    return erc1155Contract.balanceOf(address, tokenId);
};

nftSdk.connectContract = (contract, contractABI) => {
    let signer = nftSdk.getSigner();
    return new ethers.Contract(contract, contractABI, signer);
};

nftSdk.getNftContract = () => {
    let contract = nftSdk.nftContract;
    if (!contract) {
        contract = nftSdk.connectContract(MARKET_CONTRACT, NftMarket_ABI);
        nftSdk.nftContract = contract;
    }
    return contract;
};

nftSdk.make = async (_token, _tokenId, _endTime, _payToken, _price, _type, _data, _isProvideNft, eth_amount = 0) => {
    const options = { value: eth_amount };
    const marketContract = nftSdk.getNftContract();
    return marketContract.make(_token, _tokenId, _endTime, _payToken, _price, _type, _data, _isProvideNft, options);
};

nftSdk.take = async (_orderId, address, eth_amount) => {
    const options = { value: eth_amount };
    const marketContract = nftSdk.getNftContract();
    return marketContract.take(_orderId, address, options);
};

nftSdk.cancel = async (_orderId) => {
    const marketContract = nftSdk.getNftContract();
    return marketContract.cancel(_orderId);
};

nftSdk.allowance = async (token_contract, user, spender) => {
    let erc20Contract = nftSdk.connectContract(token_contract, IERC20_ABI);
    return erc20Contract.allowance(user, spender);
};

nftSdk.approve = async (token_contract, spender) => {
    let erc20Contract = nftSdk.connectContract(token_contract, IERC20_ABI);
    return erc20Contract.approve(spender, constants.MaxUint256);
};

nftSdk.isApprovedForAll = function (nft_contract, account) {
    const contract = nftSdk.connectContract(nft_contract, IERC721_ABI);
    return contract.isApprovedForAll(account, MARKET_CONTRACT);
};
nftSdk.erc721Transfer = function (nft_contract, from, to, tokenId) {
    const contract = nftSdk.connectContract(nft_contract, IERC721_ABI);
    return contract.safeTransferFrom(from, to, tokenId);
};
nftSdk.erc1155Transfer = function (nft_contract, from, to, tokenId, amount) {
    const contract = nftSdk.connectContract(nft_contract, IERC1155_ABI);
    return contract.safeTransferFrom(from, to, tokenId, amount, Buffer.from(''));
};
nftSdk.setApprovalForAll = function (nft_contract) {
    const contract = nftSdk.connectContract(nft_contract, IERC721_ABI);
    return contract.setApprovalForAll(MARKET_CONTRACT, true);
};

nftSdk.waitForTransaction = async (hash) => {
    let provider = nftSdk.getProvider();
    return provider.waitForTransaction(hash);
};
export default nftSdk;
